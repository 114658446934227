<!--  -->
<template>
  <div class="xzgdFooter">
    <!-- <div class="link">
      <div>
        <div class="link-title">友情链接</div>
        <span style="marginRight: 15px;"><a href="http://www.china.com.cn/" target="_blank">中国网</a></span>
        <span><a href="https://www.news.cn/" target="_blank">新华网</a></span>

      </div>
      <div style="display: flex;">
        <div>
          <img src="../assets/img/foot.png" style="height: 110px;objectFit: contain;">
        </div>
      </div>
      <div style="display: flex;">
        <div style="marginRight: 50px;">
          <div><img src="../assets/img/wdxz.jpg" style="width: 110px;height: 110px;"></div>
          <div class="link-title" style="textAlign: center;">我的徐州</div>
        </div>
        <div>
          <div><img src="../assets/img/wxxz.jpg" style="width: 110px;height: 110px;"></div>
          <div class="link-title" style="textAlign: center;">无线徐州</div>
        </div>
      </div>
    </div> -->
    <div style="padding: 20px 0;">
      <p class="tips">徐州广播电视传媒集团（徐州广播电视台） 版权所有</p>
      <p class="tips">苏ICP备10017019号-1</p>
    </div>

    <!-- <p class="tips">12321网络不良与垃圾信息举报受理中心|网络违法犯罪网站举报|中国互联网违法与不良信息举报中心|江苏省网络视听违规节目举报窗口|涉未成年人网络违法和不良信息举报电话：0516-85690093
      举报心想：wwwhuaihaitv@126.com</p>
    <p class="tips">徐州广播电视传媒集团（徐州广播电视台） 版权所有法律顾问：江苏茂通律师事务所</p> -->
  </div>
</template>

<script>
export default {
  name: "xzgdFooter",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
.xzgdFooter {
  // height: 402px;
  width: 100%;
  color: #fff;
  background-color: #222324;

  .link {
    width: 1200px;
    // height: 200px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;

    a {
      text-decoration: none;
      color: rgba(255, 255, 255, .6);
      font-size: 14px;
    }

    .link-title {
      font-size: 16px;
      color: rgba(255, 255, 255, .6);
    }
  }

  .tips {
    text-align: center;
    color: rgba(255, 255, 255, .8);
    font-size: 12px;
  }

  .tips:first-child {
    margin-bottom: 8px;
  }
}
</style>