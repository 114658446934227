<template>
  <div id="app">
    <div class="home">
      <div class="home-top">
        <div style="height: 100%;" class="home-logo">
          <img src="./assets/img/toplogo.png" style="height: 58px;verticalAlign: middle;">
        </div>
        <div class="home-weather">
          <div class="home-weather-item">
            <span>{{ todayWeekDay }}</span>
            <span class="home-weather-xz">徐州 <img style="width: 20px;height: 20px;"
                :src="weather.weatherPic"><span>气温：</span><span>{{ weather.temperature
                }}</span></span>
            <span>空气质量：优</span>
          </div>
        </div>
      </div>
    </div>
    <div class="home-body">
      <div class="home-nav">
        <div style="height: 80px;" class="menuItem">
          <div @click="changeRouter('/')" class="menuItem-contain">
            <span :class="{ navActive: activeIndex == '/' }" class="navbarItem">首页</span>
          </div>
        </div>
        <div style="height: 80px;" class="menuItem">
          <div class="menuItem-contain">
            <span class="navbarItem" :class="{ navActive: activeIndex == '/introduce' }">集团介绍<i
                class="el-icon-arrow-down"></i></span>
            <menuTree :list="introduceList" class="menuTreeItem"></menuTree>
          </div>
        </div>
        <div style="height: 80px;" class="menuItem">
          <div class="menuItem-contain" @click="changeRouter('/news')">
            <span class="navbarItem" :class="{ navActive: activeIndex == '/news' }">要闻动态</span>
            <!-- <menuTree :list="newsList" class="menuTreeItem"></menuTree> -->
          </div>
        </div>
        <div style="height: 80px;" class="menuItem">
          <div class="menuItem-contain">
            <span class="navbarItem" :class="{ navActive: activeIndex == '/tv' }">电视频道<i class="el-icon-arrow-down"></i>
            </span>
            <menuTree :list="tvTreeList" class="menuTreeItem"></menuTree>
          </div>
        </div>
        <div style="height: 80px;" class="menuItem">
          <div class="menuItem-contain">
            <span class="navbarItem" :class="{ navActive: activeIndex == '/channel' }">广播频率<i
                class="el-icon-arrow-down"></i></span>
            <menuTree :list="channelTreeList" class="menuTreeItem"></menuTree>
          </div>
        </div>
        <div style="height: 80px;" class="menuItem">
          <div class="menuItem-contain" @click="changeRouter('/digit')">
            <span class="navbarItem" :class="{ navActive: activeIndex == '/digit' }">数智广电</span>
          </div>
        </div>
        <div style="height: 80px;" class="menuItem">
          <div class="menuItem-contain" @click="changeRouter('/program')">
            <span class="navbarItem" :class="{ navActive: activeIndex == '/program' }">精品力作</span>
            <!-- <menuTree :list="bestTreeList" class="menuTreeItem"></menuTree> -->
          </div>
        </div>
        <div style="height: 80px;" class="menuItem">
          <div class="menuItem-contain">
            <span class="navbarItem" :class="{ navActive: activeIndex == '/culture' }">通知公告<i
                class="el-icon-arrow-down"></i></span>
            <menuTree :list="noticeTreeList" class="menuTreeItem"></menuTree>
          </div>
        </div>
        <div style="height: 80px;" class="menuItem">
          <div class="menuItem-contain" @click="changeRouter('/about')">
            <span class="navbarItem" :class="{ navActive: activeIndex == '/about' }">关于我们</span>
          </div>
        </div>
      </div>
    </div>

    <!-- <keep-alive>
      <router-view ></router-view>
    </keep-alive> -->



    <router-view style="transform: translateY(-80px);"></router-view>



    <xzgdFooter></xzgdFooter>
  </div>
</template>

<script>
import moment from 'moment';
import xzgdHeader from './pages/xzgdHeader.vue'
import xzgdFooter from './pages/xzgdFooter.vue'
import menuTree from './components/tree.vue'

export default {
  name: 'App',
  components: {
    xzgdHeader,
    xzgdFooter,
    menuTree
  },
  data() {
    return {
      weather: {
        temperature: '',
        weather: '',
        weatherPic: ''
      },
      router: true,
      activeIndex: '/',
      tvTreeList: [
        {
          title: '主持人',
          route: '/host'
        },
        {
          title: '品牌栏目',
          route: '/column'
        },
        {
          title: '频道列表',
          route: '/tv'
        },
      ],
      channelTreeList: [
        {
          title: '主持人',
          route: '/broadcastHost'
        },
        {
          title: '品牌栏目',
          route: '/columnBroadcast'
        },

        {
          title: '频率列表',
          route: '/channel'
        },
      ],
      introduceList: [
        {
          title: '集团介绍',
          route: '/introduce'
        },
        {
          title: '领导班子',
          route: '/leader'
        },
        {
          title: '组织架构',
          route: '/organization'
        },
      ],
      newsList: [
        {
          title: '集团要闻',
          route: '/news'
        },
        {
          title: '工作动态',
          route: '/work'
        },
      ],
      bestTreeList: [
        {
          title: '国家精选',
          route: '/program'
        },
        {
          title: '省级精选',
          route: '/program'
        },
        {
          title: '强国力推',
          route: '/'
        },
      ],
      noticeTreeList: [
        {
          title: '招聘',
          route: '/recruit'
        },
        {
          title: '公告',
          route: '/formula'
        },
      ],
    }
  },
  methods: {
    handleSelect(key) {

    },
    changeRouter(data) {
      this.activeIndex = data;
      window.localStorage.setItem('route', data)
      this.$router.push({ path: data }, () => { }, () => { })
    },
    getWeather() {
      this.$http
        .get(`https://xjl.nicewifi.cn/mall/buyer/other/weather/now?adCode=320300`, {
        })
        .then(res => {
          this.weather = res.data.data
        })
        .catch(function (error) {
        });
    },
    show(data) {
      if (this[data]) return
      this[data] = !this[data]
      // this.isShowTvTree = !this.isShowTvTree
    },
    close(data) {
      if (!this[data]) return
      this[data] = false
    },
    resetBg() {
      const nav = document.querySelector('.home-body')
      nav.style.backgroundColor = 'rgba(255,255,255,0.17)'
      nav.style.backdropFilter = 'blur(2px)'
    }
  },
  async created() {
    let route = window.localStorage.getItem('route')
    if (route) this.activeIndex = route
    await this.getWeather()
  },
  computed: {
    todayWeekDay() {
      let obj = {
        0: '星期日',
        1: '星期一',
        2: '星期二',
        3: '星期三',
        4: '星期四',
        5: '星期五',
        6: '星期六',
      }
      let day = moment().weekday()
      return obj[day]
    }
  },
  watch: {
    '$route': {
      handler(cur) {
        if (cur.path.includes('news') || cur.path.includes('newsDetails') || cur.path.includes('work') || cur.path.includes('workDetails')) {
          this.activeIndex = '/news';
          window.localStorage.setItem('route', '/news')
        } else if (cur.path.includes('broadcastHost') || cur.path.includes('columnBroadcastDetails') || cur.path.includes('columnBroadcast')) {
          this.activeIndex = '/channel';
          window.localStorage.setItem('route', '/channel')
        } else if (cur.path.includes('host') || cur.path.includes('hostDetails') || cur.path.includes('column')) {
          this.activeIndex = '/tv';
          window.localStorage.setItem('route', '/tv')
        } else if (cur.path.includes('introduce') || cur.path.includes('leader') || cur.path.includes('organization')) {
          this.activeIndex = '/introduce';
          window.localStorage.setItem('route', '/introduce')
        } else if (cur.path.includes('program') || cur.path.includes('programDetails')) {
          window.localStorage.setItem('route', '/program')
        } else if (cur.path.includes('formula') || cur.path.includes('formulaDetails') || cur.path.includes('recruit') || cur.path.includes('recruitDetails')) {
          this.activeIndex = '/culture';
          window.localStorage.setItem('route', '/culture')
        } else {
          this.activeIndex = cur.path;
          window.localStorage.setItem('route', cur.path)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  // transition: background-color 0.3s linear;

  // background-color: rgba(255, 255, 255, .17);
  // backdrop-filter: blur(5px);
  // background: linear-gradient(to bottom, #02BFF1, #0193C8);
  position: relative;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 78px;

  .home-top {
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.home-body {
  backdrop-filter: blur(2px);
  width: 100%;
  position: relative;
  z-index: 99;
  background-color: rgba(255, 255, 255, .17);
  transition: background-color 0.3s linear;


  .home-nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    height: 54px;
    min-width: 770px;
    height: 80px;
    width: 1200px;
    margin: auto;
    // backdrop-filter: blur(5px);
    background-color: transparent;


    .menuItem {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      // padding-top: 10px;
      font-size: 18px;
      position: relative;
      flex: 1;

      .menuItem-contain-line {
        position: absolute;
        bottom: 3.5px;
        width: 24px;
        height: 3px;
        background-color: #000;
        border-radius: 1px;
        left: 50%;
        transform: translateX(-50%);
      }

      .menuItem-contain {
        height: 26px;
        cursor: pointer;
        box-sizing: border-box;


        .menuTreeItem {
          // display: none;
          max-height: 0px;
          transition: max-height 0.2s linear;
        }
      }

    }

    .menuItem:hover {

      // background-color: rgba(0, 158, 210, 0.541);
      .navbarItem {
        font-weight: 700;

      }
    }

    .menuItem:hover .menuTreeItem {
      // opacity: 1;
      max-height: 500px;
      transition: max-height 1s linear;
      // display: block;

    }

  }
}

.home-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  min-width: 384px;
}


.home-weather {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  color: #000;

  .home-weather-item {
    display: flex;
    align-items: center;
  }
}

.home-body:hover {
  // backdrop-filter: blur(0);
  // background-color: rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 1);
  backdrop-filter: blur(0);
  // animation: colorChange 0.3s 1 forwards;
}

@keyframes colorChange {

  0% {
    background-color: rgba(255, 255, 255, .17);
    backdrop-filter: blur(5px);
  }

  100% {
    background-color: rgba(255, 255, 255, 1);
    backdrop-filter: blur(0);
  }
}

/deep/ .el-menu.el-menu--horizontal {
  border: 0px;

}

/deep/ .el-menu-demo {
  background-color: transparent;
}

// .el-menu-item {
//   background-color: rgba(0, 0, 0, 0.4);
// }

.el-menu-item.is-active {
  background-color: transparent !important;
}

.navActive {
  font-weight: 700;
}

.navbarItem {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}
</style>
<style>
/* body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
  border: 1.5px solid #7e7e7e;
  border-radius: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
  border: 2px solid #616161;
  border-radius: 8px;
} */

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(0, 173, 224, .3);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

.home-weather-xz {
  display: inline-block;
  margin: 0 20px;
  vertical-align: middle;
  position: relative;
}

.home-weather-xz::before {
  content: '';
  display: block;
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 1px;
  background-color: rgba(0, 0, 0, .17);
}

.home-weather-xz::after {
  content: '';
  display: block;
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 1px;
  background-color: rgba(0, 0, 0, .17);
}
</style>
