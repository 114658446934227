<!--  -->
<template>
  <div class="tree">
    <ul>
      <li v-for="item in list" @click.stop.prevent="goRouter(item)">{{ item.title }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "tree",
  props: {
    list: {
      default() {
        return []
      }
    }
  },
  watch: {

  },
  computed: {

  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    goRouter(item) {
      this.$router.push({ path: item.route }, () => { }, () => { })
    }
  }
}
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
.tree {
  position: absolute;
  // padding: 15px 3px;
  z-index: 999;
  // background-image: url(../assets/img/dowm_bg01.png);
  background-color: rgba(255, 255, 255, .8);
  border: 1px solid rbga(0, 0, 0, .07);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  left: 0;
  top: 80px;
  box-sizing: border-box;
  overflow: hidden;
  // border-radius: 5px;

  ul {
    list-style: none;
    margin: 0 5px;
    padding: 0;

    li {
      font-size: 16px;
      color: #323232;
      text-align: center;
      height: 36px;
      line-height: 36px;
      border-bottom: solid 1px #eaeaea;
    }

    li:first-child {
      margin-top: 10px;
    }

    li:last-child {
      margin-bottom: 10px;
      border-bottom: 0;

    }

    li:hover {
      color: #0193C8;
    }
  }
}
</style>