import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './css/global.less'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueLazyload from 'vue-lazyload'
import { getRequest } from './axios/index.js'
import axios from 'axios'

Vue.use(ElementUI);
Vue.use(VueLazyload, {
  //完全显示的时候加载
  preLoad: 1,
  //失败时显示的图片
  error: require('./assets/img/error.png'),
  //加载时显示的GIF图
  loading: require('./assets/img/loading.gif'),
  //尝试加载几次
  attempt: 1
});

const instance = axios.create({
  headers: {
    'content-type': 'application/x-www-form-urlencoded'
  }
})

Vue.prototype.$http = axios
Vue.prototype.$get = getRequest
Vue.prototype.$formHttp = instance
Vue.config.productionTip = false
// new Vue({
//   render: h => h(App),
//   router
// }).$mount('#app')

new Vue({
  el: '#app',
  router,
  render: h => h(App),
  // 预渲染
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
})