import Vue from 'vue';
import VueRouter from 'vue-router';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../pages/home.vue')
  },
  {
    path: '/digit',
    component: () => import('../pages/homeDigit.vue')
  },
  {
    path: '/news',
    component: () => import('../pages/news.vue')
  },
  {
    path: '/work',
    component: () => import('../pages/work.vue')
  },
  {
    path: '/recruit',
    component: () => import('../pages/recruit.vue')
  },
  {
    path: '/formula',
    component: () => import('../pages/formula.vue')
  },
  {
    path: '/introduce',
    component: () => import('../pages/introduce.vue')
  },
  {
    path: '/organization',
    component: () => import('../pages/organization.vue')
  },
  {
    path: '/leader',
    component: () => import('../pages/leader.vue')
  },
  {
    path: '/host',
    component: () => import('../pages/host.vue')
  },
  {
    path: '/channelHost',
    component: () => import('../pages/channelHost.vue')
  },
  {
    path: '/broadcastHost',
    component: () => import('../pages/broadcastHost.vue')
  },
  {
    path: '/channel',
    component: () => import('../pages/channel.vue')
  },
  {
    path: '/program',
    component: () => import('../pages/program.vue')
  },
  {
    path: '/column',
    component: () => import('../pages/column.vue')
  },
  {
    path: '/columnBroadcast',
    component: () => import('../pages/columnBroadcast.vue')
  },
  {
    path: '/tv',
    component: () => import('../pages/tv.vue')
  },
  {
    path: '/about',
    component: () => import('../pages/about.vue')
  },
  {
    path: '/culture',
    component: () => import('../pages/culture.vue')
  },
  {
    path: '/newsdetails/:id',
    name: 'newsdetails',
    component: () => import('../pages/newsDetails.vue'),
    meta: {
      keepAlive: false, //当前页面是否缓存，搭配keep-alive使用
      noCache: true,// 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    }
  },
  {
    path: '/work/:id',
    name: 'workdetails',
    component: () => import('../pages/workDetails.vue'),
    meta: {
      keepAlive: false, //当前页面是否缓存，搭配keep-alive使用
      noCache: true,// 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    }
  },
  {
    path: '/recruit/:id',
    name: 'recruitDetails',
    component: () => import('../pages/recruitDetails.vue'),
    meta: {
      keepAlive: false, //当前页面是否缓存，搭配keep-alive使用
      noCache: true,// 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    }
  },
  {
    path: '/formula/:id',
    name: 'formulaDetails',
    component: () => import('../pages/formulaDetails.vue'),
    meta: {
      keepAlive: false, //当前页面是否缓存，搭配keep-alive使用
      noCache: true,// 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    }
  },
  {
    path: '/cultureDetails/:id',
    name: 'cultureDetails',
    component: () => import('../pages/cultureDetails.vue'),
    meta: {
      noCache: true,// 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    }
  },
  {
    path: '/hostDetails/:id',
    name: 'hostDetails',
    component: () => import('../pages/hostDetails.vue'),
    meta: {
      keepAlive: false, //当前页面是否缓存，搭配keep-alive使用
      noCache: true,// 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    }
  },
  {
    path: '/broadcastHostDetails/:id',
    name: 'broadcastHostDetails',
    component: () => import('../pages/broadcastHostDetails.vue'),
    meta: {
      keepAlive: false, //当前页面是否缓存，搭配keep-alive使用
      noCache: true,// 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    }
  },
  {
    path: '/programDetails/:id',
    name: 'programDetails',
    component: () => import('../pages/programDetails.vue'),
    meta: {
      keepAlive: false, //当前页面是否缓存，搭配keep-alive使用
      noCache: true,// 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    }
  },
  {
    path: '/columnDetails/:id',
    name: 'columnDetails',
    component: () => import('../pages/columnDetails.vue'),
    meta: {
      keepAlive: false, //当前页面是否缓存，搭配keep-alive使用
      noCache: true,// 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    }
  },
  {
    path: '/columnBroadcastDetails/:id',
    name: 'columnBroadcastDetails',
    component: () => import('../pages/columnBroadcastDetails.vue'),
    meta: {
      keepAlive: false, //当前页面是否缓存，搭配keep-alive使用
      noCache: true,// 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    }
  },
]

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  routes,
})

export default router