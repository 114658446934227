<!--  -->
<template>
  <div class="xzgdHeader">

  </div>
</template>

<script>
export default {
  name: "xzgdHeader",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
</style>