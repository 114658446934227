import axios from 'axios'
import Vue from 'vue'



const service = axios.create({
  timeout: 180000,
  baseURL: 'https://xjl.nicewifi.cn/cms',
  // baseURL: 'http://192.168.71.216:18003',
});

export const getRequest = (url, query, resBlob) => {
  if (query !== undefined) {
    url += '?'
    const arr = Object.keys(query)
    arr.forEach((item, index) => {
      if (index == 0) {
        url += `${item}=${query[item]}`
      } else {
        url += `&${item}=${query[item]}`
      }
    })
  }
  // for (let k in query) {
  //   url = url + `&${k}=${query[k]}`;
  // }
  let data = {
    method: "get",
    url: `${url} `,
  };
  return service(data);
};

